.NotFound-info {
    position: relative; 
    top: 120px;
    display: inline-block;
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: center;
}

.NotFound-info__description {
    font-size: 25px;
    text-decoration: none;
}
