.App {
    margin: 0 auto;
    height: 100vh;
}

.App-spin {
    height: 100vh;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
