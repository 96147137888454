* {
  margin: 0;
}

.Home {
  text-align: center;
}

.Home-logo {
  height: 40vmin;
  pointer-events: none;
}

.Home-header {
  background-color: rgb(175, 200, 207);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  padding: 20px;
}

.Container {
  margin: 0 auto;
  max-width: 1280px;
  transition-duration: 1s;
}

.Home-main__content {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.Home-main__results {
  margin: 20px 0;
}

.headerSelects {
  margin: 20px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.Home-footer__button {
  border-radius: 0px;
  font-size: 16px;
  padding: 10px 18px;
  text-decoration: none;
  white-space: normal;
  border: 1px solid rgb(252, 252, 169);
  cursor: pointer;
  margin: 20px 0;
}

.Home-footer__button:hover {
  border: 1px solid rgb(206, 206, 173);
}

.onload {
  opacity: 0.1;
}